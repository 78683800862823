<template>
    <div class="home">
        <img src="../assets/images/2015/2015,-The-Fortunate-Isles,-Acrylic-,-gouache-and-acrylic-ink-on-cotton-paper,-200-x-130cm.jpg" style="width:100%">
        
    </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
