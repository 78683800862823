import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('../views/Work.vue')
  },
  {
    path: '/work/2014-2016',
    name: '2014-2016',
    component: () => import('../views/work/2014-2016.vue')
  },
  {
    path: '/2012-2013',
    name: '2012-2013',
    component: () => import('../views/work/2012-2013.vue')
  },
  {
    path: '/work/2008-2011',
    name: '2008-2011',
    component: () => import('../views/work/2008-2011.vue')
  },
  {
    path: '/work/2000-2007',
    name: '2000-2007',
    component: () => import('../views/work/2000-2007.vue')
  },
  {
    path: '/work/prints',
    name: 'prints',
    component: () => import('../views/work/prints.vue')
  },
  {
    path: '/bio',
    name: 'bio',
    component: () => import('../views/Bio.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
